<template>
    <b-row>
      <!-- <b-col cols="6">
        <b-card v-if="data && data.result" title="Company Resul" >
          <rose-chart :value="data.result" :key="data._id"/>
        </b-card>
        <div class="average" :key="data._id">
          {{ data.average }}
        </div>
      </b-col> -->
        <b-col cols="12" v-if="error">
            <h4 class="alert-heading">
                Result not available
            </h4>
            <div class="alert-body">
                {{ error }}
            </div>
        </b-col>
        <b-col v-else-if="data" cols="12">
            <b-card v-if="data && data.result" title="Average Score by Category" >
                <doughnut-chart :value="data.result" :key="data._id"/>
            </b-card>
            <div class="average" :key="data._id">
                {{data.average}}
            </div>
        </b-col>
    </b-row>
</template>
  
<script>
  import { BRow, BCol, BFormGroup, BCard, BAlert } from 'bootstrap-vue'
//   import RoseChart from '@/layouts/components/chart/rose-chart.vue'
  import DoughnutChart from '@/layouts/components/chart/doughnut-chart.vue'
  import AxiosService from '@/Service/AxiosService'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BFormGroup,
    //   RoseChart,
      DoughnutChart,
      BAlert
    },
    data() {
      return {
        data:null,
        error:null
      }
    },
    methods:{
      init(){
        this.getAnalytics()
      },
      getAnalytics(){
        AxiosService.get("/getCompanyAnalytics/").then(({error, data})=>{
        //   console.log("data", data)
          if(error) {
            this.error = error
            // console.log("error", error)
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    variant: 'error',
                    text: error,
                },
            })
          }
          else{
            this.data = data;
          }
  
        })
      }
    },
    mounted() {
      this.init();
    }
  }
  </script>
  
  <style scoped>
    .average{
      position: absolute;
      left: calc(50% - 15px);
      top: 42%;
      z-index: 99;
      font-size: 20px;
      font-weight: bold;
    }
  </style>