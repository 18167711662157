<template>
    <div>
        <e-charts
            v-if="value"
            ref="pie"
            autoresize
            :options="option"
            theme="theme-color"
            auto-resize
        />
    </div>
</template>
  
<script>
  import ECharts from 'vue-echarts'
  import 'echarts/lib/component/tooltip'
  import 'echarts/lib/component/legend'
  import 'echarts/lib/chart/pie'
  import theme from '@core/components/charts/echart/theme.json'
  
  ECharts.registerTheme('theme-color', theme)
  
  export default {
    components: {
      ECharts,
    },
    props: {
      value: {
        type:Array
      },
    },
    data() {
      return {
        option: {
            legend: {
                top: 'bottom'
            },
            toolbox: {
                show: true,
                feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                restore: { show: true },
                saveAsImage: { show: true }
                }
            },
            series: [
                {
                name: 'Doughnut Chart',
                type: 'pie',
                radius: ['40%', '70%'],
                center: ['50%', '40%'],
                itemStyle: {
                    borderRadius: 8,
                    normal : {
                     label : {
                        show: true, position: 'inner',
                        formatter : function (params){
                              return  params.data.grade
                        },
                    },
                    labelLine : {
                        show : false
                    }
                  }
                },
                data: this.value
                }
            ]
        },
      }
    },
    // mounted(){
    //     console.log("props", this.value)
    // }
  }
</script>
  
<style>
  .echarts {
    width: 100% !important;
    height: 600px !important;
  }
</style>
  